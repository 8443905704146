import React from 'react';
import './MTIHeader.scss';

const Links = () => (
  <div className="links">
    <a className="link" href="./about/index.html">About</a> |
    <a className="link" href="./tutorial/index.html">Tutorial</a> |
    <a className="link" href="./methods/index.html">Methods</a> |
    <a className="link" href="./findings/index.html">Findings</a> |
    <a className="link" href="./press/index.html">Press</a>
  </div>
);

const MTIHeader = () => (
  <div className="MTIHeader">
    <div className="main-header">
      <div className="app-title">
        <div className="umd">University of Maryland</div>
        <div className="title">COVID-19 Impact Analysis Platform</div>
      </div>
      <Links />
      
      <div className="team-logos">
        <div className="entry mti">
          <a href="https://mti.umd.edu" target="_blank" rel="noopener noreferrer"><img className="logo" src="/mti-logo.svg" alt="UMD MTI logo" /></a>
        </div>
        <div className="entry cattlab">
          <a href="https://www.cattlab.umd.edu" target="_blank" rel="noopener noreferrer"><img className="logo" src="/cattlab-logo.svg" alt="CATT Lab logo" /></a>
        </div>
      </div>
    </div>
    <div className="subheader">
      <Links />
    </div>
  </div>
);

export default MTIHeader;