const {
  MOBILITY,
  HEALTH,
  ECONOMY,
  VULNERABLE_POPULATION,
  FIELD_CATEGORIES,
} = require('./FieldCategories.js');
const {
  TOTAL,
  AVERAGE,
  STATIC,
  MAX,
} = require('./AggregationTypes.js');
const {
  PASS,
  FAIL,
  WARN,
  NOT_APPLICABLE,
} = require('./Feedback.js');

// Mobility
const SD_CONFORMITY_INDEX = "Social distancing index";
const PERCENT_STAYING_HOME = "% staying home";
const TRIPS_PER_PERSON = "Trips/person";
const PERCENT_OUT_OF_COUNTY_TRIPS = "% out-of-county trips";
const PERCENT_OUT_OF_STATE_TRIPS = "% out-of-state trips";
const MILES_TRAVELED_PER_PERSON = "Miles/person";
const WORK_TRIPS_PER_PERSON = "Work trips/person";
const NON_WORK_TRIPS_PER_PERSON = "Non-work trips/person";
const TRANSIT = "Transit mode share";

module.exports.SD_CONFORMITY_INDEX = SD_CONFORMITY_INDEX;
module.exports.PERCENT_STAYING_HOME = PERCENT_STAYING_HOME;
module.exports.TRIPS_PER_PERSON = TRIPS_PER_PERSON;
module.exports.PERCENT_OUT_OF_COUNTY_TRIPS = PERCENT_OUT_OF_COUNTY_TRIPS;
module.exports.PERCENT_OUT_OF_STATE_TRIPS = PERCENT_OUT_OF_STATE_TRIPS;
module.exports.MILES_TRAVELED_PER_PERSON = MILES_TRAVELED_PER_PERSON;
module.exports.WORK_TRIPS_PER_PERSON = WORK_TRIPS_PER_PERSON;
module.exports.NON_WORK_TRIPS_PER_PERSON = NON_WORK_TRIPS_PER_PERSON;
module.exports.TRANSIT = TRANSIT;

// Health
const CASES = "New COVID cases";
const NEW_CASES_PER_1000PP = "New cases/1000 people";
const ACTIVE_CASES = "Active cases/1000 people";
const IMPORTED_CASES = "Imported COVID cases";
const COVID_EXPOSURE = "COVID exposure/1000 people";
const COVID_TREND = "#days: decreasing COVID cases";
const ILI_TREND = "#days: decreasing ILI cases";
const TEST_CAPACITY = "Testing capacity";
const TESTS_DONE = "Tests done/1000 people";
const CONTACT_TRACING = "# contact tracing workers/1000 people";
const BED_UTILIZATION = "% hospital bed utilization";
const ICU_UTILIZATION = "% ICU utilization";
const TOTAL_BEDS = "Hospital beds/1000 people";
const TOTAL_ICU = "ICUs/1000 people";
const MEDICAL_SUPPLY = "Ventilator shortage";

module.exports.CASES = CASES;
module.exports.NEW_CASES_PER_1000PP = NEW_CASES_PER_1000PP;
module.exports.ACTIVE_CASES = ACTIVE_CASES;
module.exports.IMPORTED_CASES = IMPORTED_CASES;
module.exports.COVID_EXPOSURE = COVID_EXPOSURE;
module.exports.COVID_TREND = COVID_TREND;
module.exports.ILI_TREND = ILI_TREND;
module.exports.TEST_CAPACITY = TEST_CAPACITY;
module.exports.TESTS_DONE = TESTS_DONE;
module.exports.CONTACT_TRACING = CONTACT_TRACING;
module.exports.BED_UTILIZATION = BED_UTILIZATION;
module.exports.ICU_UTILIZATION = ICU_UTILIZATION;
module.exports.TOTAL_BEDS = TOTAL_BEDS;
module.exports.TOTAL_ICU = TOTAL_ICU;
module.exports.MEDICAL_SUPPLY = MEDICAL_SUPPLY;

// Economy
const UNEMPLOYMENT_CLAIMS = "Unemployment claims/1000 people";
const UNEMPLOYMENT_RATE = "Unemployment rate";
const WORK_FROM_HOME_PERCENT = "% working from home";
const INFLATION = "Cumulative inflation rate";
const CONSUMPTION_REDUCTION = "% change in consumption";

module.exports.UNEMPLOYMENT_CLAIMS = UNEMPLOYMENT_CLAIMS;
module.exports.UNEMPLOYMENT_RATE = UNEMPLOYMENT_RATE;
module.exports.WORK_FROM_HOME_PERCENT = WORK_FROM_HOME_PERCENT;
module.exports.INFLATION = INFLATION;
module.exports.CONSUMPTION_REDUCTION = CONSUMPTION_REDUCTION;

// Vulnerable population
const AGE_GT_60 = "% people older than 60";
const INCOME = "Median income";
const PERCENT_AFRICAN_AMERICAN = "% African Americans";
const PERCENT_HISPANIC_AMERICAN = "% Hispanic Americans";
const PERCENT_MALE = "% Male";
const POPULATION_DENSITY = "Population density";
const EMPLOYMENT_DENSITY = "Employment density";
const HOT_SPOTS = "# hot spots/1000 people";
const DEATH_RATE = "COVID death rate";
const POPULATION = "Population";

module.exports.AGE_GT_60 = AGE_GT_60;
module.exports.INCOME = INCOME;
module.exports.PERCENT_AFRICAN_AMERICAN = PERCENT_AFRICAN_AMERICAN;
module.exports.PERCENT_HISPANIC_AMERICAN = PERCENT_HISPANIC_AMERICAN;
module.exports.PERCENT_MALE = PERCENT_MALE;
module.exports.POPULATION_DENSITY = POPULATION_DENSITY;
module.exports.EMPLOYMENT_DENSITY = EMPLOYMENT_DENSITY;
module.exports.HOT_SPOTS = HOT_SPOTS;
module.exports.DEATH_RATE = DEATH_RATE;
module.exports.POPULATION = POPULATION;

// Ranks



// Display functions
const roundWholeNumberDisplayFunction = value => Math.round(value).toLocaleString();
const roundTenthDisplayFunction = value => (Math.round(value * 10) / 10).toLocaleString();
const roundHundrethDisplayFunction = value => (Math.round(value * 100) / 100).toLocaleString();
const roundThousandthsDisplayFunction = value => (Math.round(value * 1000) / 1000).toLocaleString();
const roundTenThousandthsOMGDisplayFunction = value => (Math.round(value * 10000) / 10000).toLocaleString();
const roundWholeNumberDisplayFunctionPercent = value => roundWholeNumberDisplayFunction(value) + '%';
const roundTenthDisplayFunctionPercent = value => roundTenthDisplayFunction(value) + '%';
const roundHundrethDisplayFunctionPercent = value => roundHundrethDisplayFunction(value) + '%';
const populationDisplayFunction = value => {
  if (value < 1000) {
    return value.toLocaleString();
  } else if (value < 1000000) {
    return Math.round(value / 100) / 10 + " K";
  } else {
    return Math.round(value / 10000) / 100 + " M";
  }
};
const moneyDisplayFunction = value => '$' + roundWholeNumberDisplayFunction(value);

const FIELDS = {};
FIELDS[SD_CONFORMITY_INDEX] = {
  name: "Social distancing index",
  column: "social_distancing_index",
  rankColumn: "social_distancing_index_rank",
  description: "The extent residents and visitors practice social distancing",
  // description: "An integer from 0~100 that represents the extent residents and visitors are practicing social distancing. “0” indicates no social distancing is observed in the community, while “100” indicates all residents are staying at home and no visitors are entering the county. See “Method” page for details. Calculated by MTI.",
  category: MOBILITY,
  aggregationType: AVERAGE,
  displayFunction: roundWholeNumberDisplayFunction,
  domain: [20, 80],
  isSeraComponent: true,
  isDefault: true,
};
FIELDS[PERCENT_STAYING_HOME] = {
  name: "% staying home",
  column: "percent_staying_home",
  description: "Percentage of residents staying at home",
  // description: "Percentage of residents staying at home (i.e., no trips with a non-home trip end more than one mile away from home). Calculated by MTI.",
  category: MOBILITY,
  aggregationType: AVERAGE,
  displayFunction: roundWholeNumberDisplayFunctionPercent,
  domain: [15, 40],
  isDefault: true,
};
FIELDS[TRIPS_PER_PERSON] = {
  name: "Trips/person",
  column: "trips_per_person",
  description: "Average number of trips taken per person",
  // description: "Average number of all trips taken per person per day. Calculated by MTI.",
  category: MOBILITY,
  aggregationType: AVERAGE,
  displayFunction: roundHundrethDisplayFunction,
  domain: [2, 4],
  normalized: true,
};
FIELDS[PERCENT_OUT_OF_COUNTY_TRIPS] = {
  name: "% out-of-county trips",
  column: "percent_out_of_county_trips",
  rankColumn: "percent_out_of_county_trips_rank",
  description: "% trips from out of the county",
  // description: "Percentage of all trips taken that travel out of a county. Additional information on the origins and destinations of these trips at the county-to-county level is available, but not currently shown on the platform. Calculated by MTI.",
  category: MOBILITY,
  aggregationType: AVERAGE,
  displayFunction: roundTenthDisplayFunctionPercent,
  domain: [0, 40],
  isSeraComponent: false,
};
FIELDS[PERCENT_OUT_OF_STATE_TRIPS] = {
  name: "% out-of-state trips",
  column: "percent_out_of_state_trips",
  rankColumn: "percent_out_of_state_trips_rank",
  description: "% trips from out of the state",
  // description: "Percentage of all trips taken that travel out of a county. Additional information on the origins and destinations of these trips at the county-to-county level is available, but not currently shown on the platform. Calculated by MTI.",
  category: MOBILITY,
  aggregationType: AVERAGE,
  displayFunction: roundTenthDisplayFunctionPercent,
  domain: [0, 40],
  isSeraComponent: true,
};
FIELDS[MILES_TRAVELED_PER_PERSON] = {
  name: "Miles/person",
  column: "miles_per_person",
  description: "Average person-miles traveled on all modes",
  // description: "Average person-miles traveled on all modes (car, train, bus, plane, bike, walk, etc.) per person per day. Calculated by MTI.",
  category: MOBILITY,
  aggregationType: AVERAGE,
  displayFunction: roundTenthDisplayFunction,
  domain: [15, 40],
  normalized: true,
};
FIELDS[WORK_TRIPS_PER_PERSON] = {
  name: "Work trips/person",
  column: "work_trips_per_person",
  description: "Number of work trips per person",
  category: MOBILITY,
  aggregationType: AVERAGE,
  displayFunction: roundHundrethDisplayFunction,
  domain: [0.25, 1],
  normalized: true,
};
FIELDS[NON_WORK_TRIPS_PER_PERSON] = {
  name: "Non-work trips/person",
  column: "non_work_trips_per_person",
  description: "Number of non-work trips per person",
  category: MOBILITY,
  aggregationType: AVERAGE,
  displayFunction: roundHundrethDisplayFunction,
  domain: [2, 3],
  normalized: true,
};
FIELDS[TRANSIT] = {
  name: "Transit mode share",
  column: "transit_mode_share",
  description: "Percentage of transit mode share",
  category: MOBILITY,
  aggregationType: AVERAGE,
  displayFunction: roundHundrethDisplayFunctionPercent,
  domain: [0, 1],
  isFixed: true,
};

// Health
FIELDS[COVID_TREND] = {
  name: "# days: decreasing COVID cases",
  column: "days_decreasing_covid_cases",
  rankColumn: "days_decreasing_covid_cases_rank",
  description: "Number of days with decreasing COVID-19 cases",
  category: HEALTH,
  aggregationType: MAX,
  displayFunction: roundWholeNumberDisplayFunction,
  isSeraComponent: true,
  feedbackFunction: value => value >= 14 ? PASS : FAIL,
};
FIELDS[ILI_TREND] = {
  name: "# days: decreasing ILI cases",
  column: "days_decreasing_ili",
  rankColumn: "days_decreasing_ili_cases_rank",
  description: "Number of days with decreasing influenza-like illness trend",
  category: HEALTH,
  aggregationType: MAX,
  displayFunction: roundWholeNumberDisplayFunction,
  isSeraComponent: true,
  feedbackFunction: value => value >= 14 ? PASS : FAIL,
};
FIELDS[TEST_CAPACITY] = {
  name: "Testing capacity gap",
  column: "testing_capacity",
  rankColumn: "testing_capacity_rank",
  description: "Ability to provide enough tests based on WHO-recommended positive test rate proxy",
  category: HEALTH,
  aggregationType: AVERAGE,
  displayFunction: roundTenthDisplayFunctionPercent,
  isSeraComponent: true,
  feedbackFunction: value => value < 10 ? PASS : value <= 12 ? WARN : FAIL,
};
FIELDS[CONTACT_TRACING] = {
  name: "# contact tracing workers/1000 people",
  column: "contact_tracing_workers_per_1k",
  rankColumn: "contact_tracing_workers_per_1k_rank",
  description: "Number of workers enrolled in contact tracing per 1000 people",
  category: HEALTH,
  aggregationType: AVERAGE,
  displayFunction: value => {
    return value === null ? 'N/A' : roundThousandthsDisplayFunction(value);
  },
  isSeraComponent: true,
  feedbackFunction: value => {
    if(value === null) {
      return NOT_APPLICABLE;
    }
    return value >= .3 ? PASS : value >= .15 ? WARN : FAIL
  },
};
// currently BED_UTILIZATION data never changes; likely an error in data
FIELDS[BED_UTILIZATION] = {
  name: "% hospital bed utilization",
  column: "percent_hospital_bed_utilization",
  rankColumn: "percent_hospital_bed_utilization_rank",
  description: "% hospital beds occupied with patients",
  category: HEALTH,
  aggregationType: AVERAGE,
  displayFunction: roundHundrethDisplayFunctionPercent,
  isSeraComponent: true,
  feedbackFunction: value => value <= 50 ? PASS : value <= 90 ? WARN : FAIL,
};
FIELDS[ICU_UTILIZATION] = {
  name: "% ICU utilization",
  column: "percent_icu_utilization",
  rankColumn: "percent_icu_utilization_rank",
  description: "% ICU units occupied with patients",
  category: HEALTH,
  aggregationType: AVERAGE,
  displayFunction: roundHundrethDisplayFunctionPercent,
  isSeraComponent: true,
  feedbackFunction: value => value <= 50 ? PASS : value <= 90 ? WARN : FAIL,
};
FIELDS[CASES] = {
  name: "New COVID cases",
  column: "new_covid_cases",
  description: "Number of COVID-19 daily new cases",
  category: HEALTH,
  aggregationType: TOTAL,
  displayFunction: roundWholeNumberDisplayFunction,
};
FIELDS[NEW_CASES_PER_1000PP] = {
  name: "New cases/1000 people",
  column: "new_cases_per_1k",
  rankColumn: "new_cases_per_1k_rank",
  description: "Number of COVID-19 daily new cases per 1000 people (3-day moving average)",
  category: HEALTH,
  aggregationType: TOTAL,
  displayFunction: roundTenThousandthsOMGDisplayFunction,
  isSeraComponent: true,
  normalized: true,
};
FIELDS[ACTIVE_CASES] = {
  name: "Active cases/1000 people",
  column: "active_cases_per_1k",
  description: "Number of active COVID-19 cases per 1000 people",
  category: HEALTH,
  aggregationType: AVERAGE,
  displayFunction: roundHundrethDisplayFunction,
  normalized: true,
};
FIELDS[IMPORTED_CASES] = {
  name: "Imported COVID cases",
  column: "imported_covid_cases",
  rankColumn: "imported_covid_cases_rank",
  description: "Number of daily external trips by infectious persons from out of state/county",
  category: HEALTH,
  aggregationType: TOTAL,
  displayFunction: roundWholeNumberDisplayFunction,
  isSeraComponent: true,
  isDefault: true,
};
FIELDS[COVID_EXPOSURE] = {
  name: "COVID exposure/1000 people",
  column: "covid_exposure_per_1k",
  description: "Number of residents already exposed to coronavirus per 1000 people",
  category: HEALTH,
  aggregationType: STATIC,
  displayFunction: roundHundrethDisplayFunction,
};
FIELDS[TESTS_DONE] = {
  name: "Tests done/1000 people",
  column: "tests_done_per_1k",
  description: "Number of COVID-19 tests already completed per 1000 people",
  category: HEALTH,
  aggregationType: STATIC,
  displayFunction: roundHundrethDisplayFunction,
  normalized: true,
};
FIELDS[TOTAL_BEDS] = {
  name: "Hospital beds/1000 people", // Why not per person or a raw count of beds
  column: "hospital_beds_per_1k",
  description: "Number of hospital beds per 1000 people",
  category: HEALTH,
  aggregationType: AVERAGE,
  displayFunction: roundHundrethDisplayFunction,
  normalized: true,
  isFixed: true,
};
FIELDS[TOTAL_ICU] = {
  name: "ICUs/1000 people", // Why not per person or a raw count of beds
  column: "icu_per_1k",
  description: "Number of ICU beds per 1000 people",
  category: HEALTH,
  aggregationType: AVERAGE,
  displayFunction: roundHundrethDisplayFunction,
  normalized: true,
  isFixed: true,
};
FIELDS[MEDICAL_SUPPLY] = {
  name: "Ventilator needs",
  column: "ventilator_shortage",
  rankColumn: "ventilator_shortage_rank",
  description: "Number of ventilators needed for COVID-19 patients",
  category: HEALTH,
  aggregationType: AVERAGE,
  displayFunction: roundWholeNumberDisplayFunction,
  isSeraComponent: true,
};

// Economy
FIELDS[UNEMPLOYMENT_CLAIMS] = {
  name: "Unemployment claims/1000 people",
  column: "unemployment_claims_per_1k",
  description: "New weekly unemployment insurance claims per 1000 workers",
  category: ECONOMY,
  aggregationType: TOTAL,
  displayFunction: roundTenthDisplayFunction,
  normalized: true,
};
FIELDS[UNEMPLOYMENT_RATE] = {
  name: "Unemployment rate",
  column: "unemployment_rate",
  rankColumn: "unemployment_rate_rank",
  description: "Unemployment rate updated weekly based on UMD models",
  category: ECONOMY,
  aggregationType: AVERAGE,
  displayFunction: roundTenthDisplayFunctionPercent,
  isSeraComponent: true,
};
FIELDS[WORK_FROM_HOME_PERCENT] = {
  name: "% working from home",
  column: "percent_work_from_home",
  rankColumn: "percent_work_from_home_rank",
  description: "Percentage of workforce working from home based on UMD models",
  category: ECONOMY,
  aggregationType: AVERAGE,
  displayFunction: roundTenthDisplayFunctionPercent,
  isSeraComponent: true,
};
FIELDS[INFLATION] = {
  name: "Cumulative inflation rate",
  column: "cumulative_inflation_rate",
  description: "Overall economic condition measured by cumulative inflation rate since COVID-19 outbreak",
  category: ECONOMY,
  aggregationType: AVERAGE,
  displayFunction: roundHundrethDisplayFunctionPercent,
};
FIELDS[CONSUMPTION_REDUCTION] = {
  name: "% change in consumption",
  column: "percent_change_consumption",
  rankColumn: "percent_change_in_consumption_rank",
  description: "% change in consumption measured by changes in trips to consumption sites as a proxy",
  category: ECONOMY,
  aggregationType: AVERAGE,
  displayFunction: roundTenthDisplayFunctionPercent,
  isSeraComponent: true,
  isDefault: true,
};

// Vulnerable populations
FIELDS[AGE_GT_60] = {
  name: "% people older than 60",
  column: "percent_people_gt_60",
  rankColumn: "percent_people_gt_60_rank",
  description: "Percent of population above the age of 60",
  category: VULNERABLE_POPULATION,
  aggregationType: AVERAGE,
  displayFunction: roundWholeNumberDisplayFunctionPercent,
  isSeraComponent: true,
  isFixed: true,
};
FIELDS[INCOME] = {
  name: "Median income",
  column: "median_income",
  description: "Median income",
  category: VULNERABLE_POPULATION,
  aggregationType: AVERAGE,
  displayFunction: moneyDisplayFunction,
  isFixed: true,
};
FIELDS[PERCENT_AFRICAN_AMERICAN] = {
  name: "% African Americans",
  column: "percent_african_american",
  description: "Percentage of African Americans",
  category: VULNERABLE_POPULATION,
  aggregationType: AVERAGE,
  displayFunction: roundTenthDisplayFunctionPercent,
  isFixed: true,
};
FIELDS[PERCENT_HISPANIC_AMERICAN] = {
  name: "% Hispanic Americans",
  column: "percent_hispanic_american",
  description: "Percentage of Hispanic Americans",
  category: VULNERABLE_POPULATION,
  aggregationType: AVERAGE,
  displayFunction: roundTenthDisplayFunctionPercent,
  isFixed: true,
};
FIELDS[PERCENT_MALE] = {
  name: "% male",
  column: "percent_male",
  description: "Percentage of males",
  category: VULNERABLE_POPULATION,
  aggregationType: AVERAGE,
  displayFunction: roundHundrethDisplayFunctionPercent,
  isFixed: true,
};
FIELDS[POPULATION_DENSITY] = {
  name: "Population density",
  column: "population_density",
  description: "Population density",
  category: VULNERABLE_POPULATION,
  aggregationType: AVERAGE,
  displayFunction: roundWholeNumberDisplayFunction,
  isFixed: true,
};
FIELDS[EMPLOYMENT_DENSITY] = {
  name: "Employment density",
  column: "employment_density",
  description: "Employment density",
  category: VULNERABLE_POPULATION,
  aggregationType: AVERAGE,
  displayFunction: roundWholeNumberDisplayFunction,
  isFixed: true,
};
FIELDS[HOT_SPOTS] = {
  name: "# hot spots/1000 people",
  column: "hot_spots_per_1k",
  description: "Number of points of interests for crowd gathering per 1000 people",
  category: VULNERABLE_POPULATION,
  aggregationType: AVERAGE,
  displayFunction: roundWholeNumberDisplayFunction,
  normalized: true,
  isFixed: true,
};
FIELDS[DEATH_RATE] = {
  name: "COVID death rate",
  column: "covid_death_rate",
  rankColumn: "covid_death_rate_rank",
  description: "% deaths among all confirmed COVID-19 cases",
  category: VULNERABLE_POPULATION,
  aggregationType: AVERAGE,
  displayFunction: roundHundrethDisplayFunctionPercent,
  isDefault: true,
  isSeraComponent: true,
};
FIELDS[POPULATION] = {
  name: "Population",
  column: "population",
  description: "Total population in a state or county",
  category: VULNERABLE_POPULATION,
  aggregationType: AVERAGE,
  displayFunction: populationDisplayFunction,
  isFixed: true,
};
module.exports.FIELDS = FIELDS;

const AVAILABLE_FIELDS = [];
for(let key in FIELDS) {
  const field = FIELDS[key];
  field.className = FIELD_CATEGORIES[field.category].className;
  field.color = FIELD_CATEGORIES[field.category].baseColor;
  field.topLegendColor = FIELD_CATEGORIES[field.category].gradientTopColor;
  AVAILABLE_FIELDS.push(key);
}

module.exports.AVAILABLE_FIELDS = AVAILABLE_FIELDS;

const FIELDS_BY_CATEGORY = {};
FIELDS_BY_CATEGORY[HEALTH] = AVAILABLE_FIELDS.filter(fieldKey => FIELDS[fieldKey].category === HEALTH);
FIELDS_BY_CATEGORY[MOBILITY] = AVAILABLE_FIELDS.filter(fieldKey => FIELDS[fieldKey].category === MOBILITY);
FIELDS_BY_CATEGORY[ECONOMY] = AVAILABLE_FIELDS.filter(fieldKey => FIELDS[fieldKey].category === ECONOMY);
FIELDS_BY_CATEGORY[VULNERABLE_POPULATION] = AVAILABLE_FIELDS.filter(fieldKey => FIELDS[fieldKey].category === VULNERABLE_POPULATION);
module.exports.FIELDS_BY_CATEGORY = FIELDS_BY_CATEGORY;
