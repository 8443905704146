import React from 'react';
import {detect} from 'detect-browser';
import './DashboardIntro.scss';

const browser = detect();

const isMobile = window.mobilecheck();
const isPreferredBrowser = browser && (browser.name === 'chrome' || browser.name === 'firefox' || browser.name === 'safari');

const DashboardIntro = () => (
  <div className="DashboardIntro">
      {isMobile &&
          <p>
            <b>The COVID-19 Impact Analysis Platform is best viewed on a desktop device.</b>
          </p>
      }
      {!isMobile && !isPreferredBrowser && 
      	<p>
      		<b>The COVID-19 Impact Analysis Platform is best viewed using Chrome or Firefox</b>
      	</p>
      }
  </div>
);

export default DashboardIntro;