/**
 * A field with this aggregation type should be summed.
 */
module.exports.TOTAL = "total";

/**
 * A field with this aggregation type should be averaged.
 */
module.exports.AVERAGE = "average";

/**
 * A field with this aggregation type has values that don't change over the averaging period so selecting a single value is appropriate.
 */
module.exports.STATIC = "static";

/**
 * A field with this aggregation type should take the max for a given range
 */
module.exports.MAX = "max";