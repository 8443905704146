import React from 'react';
import moment from 'moment';
import { BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts';
import {
  FIELDS,
} from '../constants/Fields';

const LONG_DATE_FORMAT_STRING = 'MMMM D, YYYY';

const CustomTooltip = ({ active, payload }) => {
  const data = payload[0];
  if (active && data) {
    const region = data.payload['displayName'];
    const date = data.payload['date'];
    const field = FIELDS[data.dataKey];
    const fieldName = field.name; 
    const value = field.displayFunction(data.value);
    return (
      <div className="custom-tooltip">
        <div >{region}</div>
        <div>{moment(date).format(LONG_DATE_FORMAT_STRING)}</div>
        <div>{fieldName}: {value}</div>
      </div>
    );
  }

  return null;
};

export default class CustomBarChart extends React.PureComponent {

  render() {
    const {
      data,
      fieldKey,
      width,
    } = this.props;

    const field = FIELDS[fieldKey];

    return(
      <BarChart
        width={width}
        height={100}
        data={data}
        barCategoryGap={0}
        margin={{left: 0, top: 5, bottom: 0, right: 0}}
      >
        <XAxis dataKey="date" />
        <YAxis tickFormatter={field.displayFunction} />
        <Bar
          dataKey={fieldKey}
          fill={field.color}
          isAnimationActive={false}
        />
        <Tooltip 
          content={<CustomTooltip />}
          cursor={{ stroke: 'red', strokeWidth: .5, fill:'none' }}
        />
      </BarChart>
    );
  }
}

CustomBarChart.defaultProps = {
  width: 250,
};