import React from 'react';
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { STATE } from '../constants/GeographicResolutions';

const DEFAULT_MAP_COLOR = '#7E8084';

const CovidComposableMap = ({
  projection,
  geography,
  setTooltipContent,
  createTooltipContent,
  data,
  onRegionClick,
  colorScale,
  selectedField,
  selectedState,
  geographyResolution,
}) => (
  <ComposableMap projection={projection} className='CovidComposableMap'>
    <Geographies
      geography={geography}
      onMouseLeave={() => setTooltipContent(null)}
    >
      {({ geographies }) =>
        <React.Fragment>
          {
            geographies.map(geo => {
              const curr = data.find(s => s.fips === geo.id);
              let fill = DEFAULT_MAP_COLOR;
              if(colorScale && curr && (geographyResolution === STATE || selectedState === 'USA' || curr.stateName === selectedState)) {
                fill = colorScale(curr[selectedField]) || DEFAULT_MAP_COLOR;
              }

              let stroke = 'black';
              const tt = curr ? createTooltipContent(curr) : null;
              
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={fill}
                  stroke={stroke}
                  strokeWidth='0.25px'
                  onClick={() => onRegionClick(curr)}
                  onMouseEnter={() => setTooltipContent(tt)}
                />
              );
            })
          }
        </React.Fragment>
      }
    </Geographies>
  </ComposableMap>
);

export default React.memo(CovidComposableMap);