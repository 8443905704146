import React from 'react';
import CasesByDateMap from './components/CasesByDateMap';
import MTIHeader from './components/MTIHeader';
import Credits from './components/Credits';

import './CovidArticle.scss';

export default class CovidArticle extends React.Component {

  render() {
    return (
      <div className="CovidArticle">
        <MTIHeader />
        <div className="container">
          <article>
            <CasesByDateMap />
            <p>
              We built a <a href="/?dashboard=1">dashboard</a>.
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque porttitor nulla sit amet lorem varius facilisis. Phasellus vitae laoreet eros. Vivamus gravida interdum erat id auctor. Morbi rhoncus, ipsum tristique sodales auctor, dui arcu blandit sapien, et egestas enim lacus sed magna. Praesent feugiat orci sit amet lorem cursus, eu consequat massa congue. Quisque eget elit mauris. Duis facilisis justo posuere tortor ullamcorper interdum. Aliquam vitae aliquam neque. Etiam consequat varius gravida. Integer sollicitudin gravida mi, vitae egestas diam dignissim quis. Nulla facilisi.
            </p>
            <p>
              Vestibulum ac sollicitudin mi. Sed id finibus erat. Duis consectetur nec risus sed fermentum. Duis lacinia arcu et dui bibendum pretium. Cras in viverra diam. Sed tincidunt blandit sodales. Aenean elementum volutpat urna vitae convallis. Nullam blandit arcu quis ipsum auctor, vitae varius eros tristique. Morbi ac nisi sed elit feugiat facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ac arcu tristique, volutpat odio non, porttitor massa. Donec lacinia efficitur hendrerit. Nam commodo ex mauris, sed aliquam orci pharetra non.
            </p>
            <p>
              Aliquam at ornare nulla. Vestibulum non eros arcu. Quisque rutrum aliquet nisi, sed lacinia nibh sollicitudin vel. In hac habitasse platea dictumst. Cras nec lorem semper, pharetra urna sit amet, vestibulum nulla. Aliquam pulvinar ac magna quis tincidunt. Proin blandit pulvinar diam a sollicitudin. Ut ut finibus sapien. Integer eu ipsum fringilla, placerat ligula et, scelerisque lectus.
            </p>
            <p>
              In aliquam orci eu nibh pellentesque lacinia. Mauris pharetra sollicitudin augue vel bibendum. Sed scelerisque urna eu vulputate tincidunt. Praesent finibus dui quis nisi consequat viverra non a dui. Nunc varius condimentum eros, et tincidunt ipsum finibus eget. Nulla posuere enim at turpis lobortis ornare. Quisque tempor scelerisque pulvinar. Pellentesque molestie purus sollicitudin leo feugiat, id ornare justo efficitur. Pellentesque gravida odio sed leo pulvinar, quis varius neque blandit. Sed egestas magna sit amet condimentum euismod. Vivamus pulvinar turpis ligula, quis tempor justo dignissim non.
            </p>
            <p>
              Duis ut ex euismod diam rutrum consectetur sed et augue. Vestibulum molestie elit et augue convallis tincidunt. Quisque vestibulum dui dapibus, lobortis ex vitae, mattis ligula. Aenean euismod finibus risus a dapibus. Mauris vitae libero magna. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Integer vel augue sapien. Suspendisse scelerisque lacus eget risus dignissim, quis egestas est egestas. Aenean sit amet venenatis nunc. Praesent ultrices ac purus eu tempor. Vestibulum ligula quam, congue a mi eu, aliquam consectetur lectus. Praesent scelerisque purus ac lectus lobortis, eget tempor mi rhoncus. Aenean eget leo turpis.
            </p>
          </article>
        </div>
        <Credits />
      </div>
    );
  }

}