import React from 'react';
import DatePicker from "react-datepicker";
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';
import debounce from "lodash/debounce";
import CovidMap from './components/CovidMap';
import MTIHeader from './components/MTIHeader';
import DashboardIntro from './components/DashboardIntro';
import DataTable from './components/DataTable';
import {
  STATE,
  COUNTY
} from './constants/GeographicResolutions';
import {
  FIELD_CATEGORIES,
  MOBILITY,
  HEALTH,
  ECONOMY,
  VULNERABLE_POPULATION,
} from './constants/FieldCategories';
import {
  FIELDS,
  FIELDS_BY_CATEGORY,
  AVAILABLE_FIELDS,
  COVID_TREND,
  ILI_TREND,
  TEST_CAPACITY,
  CONTACT_TRACING,
  BED_UTILIZATION,
  ICU_UTILIZATION,
  SD_CONFORMITY_INDEX,
} from './constants/Fields';
import AnnouncementModal from './components/AnnouncementModal';
import {
	stringToDate,
	dateToString,
} from './utils/dateUtils';

import "react-datepicker/dist/react-datepicker.css";
import './CovidDashboard.scss';

const CURRENT_YEAR = (new Date()).getFullYear();

const API_ROOT_URL = process.env.REACT_APP_API_ROOT_URL;

// name must be at least 2 words
const formatCategoryName = name => {
  const parts = name.split(' ');
  const halfwayIndex = Math.floor(parts.length / 2);
  return <React.Fragment>
    <span>{parts.slice(0, halfwayIndex).join(' ')}</span><br /><span>{parts.slice(halfwayIndex).join(' ')}</span>
  </React.Fragment>;
};

// A map of fips codes to state names. E.g. {1: Alabama, 2: Alaska, ...}
let fipsToState = {};

// this component must handle refs, so a class component is easiest
class ReadOnlyInput extends React.Component {
  render() {
    return <input {...this.props} readOnly={true} />
  }
}

const getDefaultSelectedFields = () => AVAILABLE_FIELDS
  .filter(fieldKey => FIELDS[fieldKey].isDefault === true)
  .reduce(
    (accumulator, fieldKey) => {
      accumulator[fieldKey] = true;
      return accumulator;
    },
    {}
  );

const parseThresholdRecords = thresholdRecords => {
  const toReturn = {};
  thresholdRecords.forEach(record => {
    const thresholds = {
      [COVID_TREND]: {
        absolute: +record["days_decreasing_covid_cases_threshold_abs"],
        percentile: +record["days_decreasing_covid_cases_threshold_perc"],
      },
      [ILI_TREND]: {
        absolute: +record["days_decreasing_ili_cases_threshold_abs"],
        percentile: +record["days_decreasing_ili_cases_threshold_perc"],
      },
      [TEST_CAPACITY]: {
        absolute: +record["testing_capacity_threshold_abs"],
        percentile: +record["testing_capacity_threshold_perc"],
      },
      [CONTACT_TRACING]: {
        absolute: +record["contact_tracing_threshold_abs"], 
        percentile: +record["contact_tracing_threshold_perc"],
      },
      [BED_UTILIZATION]: {
        absolute: +record["hospital_bed_utilization_threshold_abs"],
        percentile: +record["hospital_bed_utilization_threshold_perc"],
      },
      [ICU_UTILIZATION]: {
        absolute: +record["icu_utilization_threshold_abs"],
        percentile: +record["icu_utilization_threshold_perc"],
      },
    };
    toReturn[record.date] = thresholds;
  });
  return toReturn;
};

export default class CovidDashboard extends React.Component {

  constructor(props) {
    super(props);
    this.countyInput = React.createRef();
    this.stateRadioButton = React.createRef();
    this.countyRadioButton = React.createRef();

    this.state = {
      nationRecords: [],
      states: [],
      counties: [],
      filteredRecords: [],
      geographyResolution: STATE,
      selectedState: "USA",

      dateStringsToStateThresholds: {},
      dateStringsToCountyThresholds: {},

      // A map of FieldCategories to the boolean "true". Presence in the map indicates that the column chooser for that category is open.
      openColumnChoosers: {},
      
      dateStringsToStateMapRecords: {},
      dateStringsToCountyMapRecords: {},
      
      // The minimum and maximum dates the user can choose
      minDate: null,
      maxDate: null,

      // The dates that make up the range the user chose
      fromDate: null,
      toDate: null,

      // The date that the playhead is currently on
      selectedDate: null,
      
      // A map of field keys to the boolean "true". Presence in the map indicates that field is selected
      selectedFields: getDefaultSelectedFields(),
      
      sortBy: "displayName",
      sortDescending: false,

      isLoadingStates: false,
      isLoadingCounties: false,
      isLoadingSera: false,

      // we're separately keeping track of records displayed in each of these 3 components in order to have fine-grain control
      //  over which component is displaying a record when keeping the 3 components "in sync"
      recordForSera: null,
      recordForMap: null,
      recordForTable: null,

      graphData: {},
      countyTableRecords: [],
      fipsToCountyDisplayName: null,

      selectedMapField: SD_CONFORMITY_INDEX,
    };
  }

  errorHandler = err => {
    // inspired by https://medium.com/trabe/catching-asynchronous-errors-in-react-using-error-boundaries-5e8a5fd7b971
    this.setState(() => {
      console.error(err);
      throw err;
    });
  }

  componentDidMount() {
    this.setState({isLoadingStates: true}); // hacky, but makes initial load happen correctly
    Promise.all([
      this.loadDates(),
      this.loadNationData(),
      this.loadRegionThresholds(STATE),
      this.loadRegionThresholds(COUNTY),
      this.fetchRegionNames(STATE),
      this.fetchRegionNames(COUNTY),
      this.fetchRegionPercentiles(STATE),
      this.fetchRegionPercentiles(COUNTY),
    ])
    .then(() => {
      this.loadRegionData(STATE);
      document.addEventListener("click", this.handleDocumentClick);
    })
    .catch(this.errorHandler);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleDocumentClick);
  }

  handleDocumentClick = e => {
    // Closes any column choosers that the user clicked outside of
    const {
      openColumnChoosers,
    } = this.state;
    
    // Track the number of column choosers to close and write to state once at the end
    let changeCount = 0;

    const categories = Object.keys(FIELDS_BY_CATEGORY);
    categories.forEach(category => {
      const idSelector = `#${category}-column-chooser`;
      // Is the column chooser open and did the user click outside it?
      if (category in openColumnChoosers && !document.querySelector(idSelector).contains(e.target)) {
        delete openColumnChoosers[category];
        changeCount++;
      }
    });

    // If the sera modal is open and the user clicked outside it, close it
    /*
    let {
      recordForSera,
    } = this.state;
    if (recordForSera) { //&& !document.querySelector(".sera-window").contains(e.target)
      recordForSera = null;
      changeCount++;
    }
    */

    if(changeCount > 0) {
      this.setState({
        openColumnChoosers,
        //recordForSera,
      });
    }
  };

  loadDates = () => {
    return fetch(`${API_ROOT_URL}/national/records/range`)
      .then(response => response.json())
      .then(response => {
        const minDate = stringToDate(response.min);
        const maxDate = stringToDate(response.max);
        this.setState({
          minDate,
          maxDate,
          fromDate: maxDate,
          toDate: maxDate,
          selectedDate: maxDate,
        });
      })
      .catch(this.errorHandler);
  };

  loadNationData = () => {
    // It's possible this will eventually reach a point where requesting all national records is prohibitive
    return fetch(`${API_ROOT_URL}/national/records`)
      .then(response => response.json())
      .then(records => {
        const nationRecords = [];
        records.forEach(record => {
          const newRecord = {
            date: stringToDate(record.date),
          };
          AVAILABLE_FIELDS.forEach(field => newRecord[field] = +record[field]);
          nationRecords.push(newRecord);
        });
        this.setState({
          nationRecords,
        });
      })
      .catch(this.errorHandler);
  }

  loadRegionThresholds = geographyResolution => {
    const thresholdKey = geographyResolution === STATE ? 'dateStringsToStateThresholds' : 'dateStringsToCountyThresholds';
    return fetch(`${API_ROOT_URL}/${geographyResolution}/thresholds`)
      .then(response => response.json())
      .then(thresholdRecords => {
        this.setState({
          [thresholdKey]: parseThresholdRecords(thresholdRecords),
        });
      })
      .catch(this.errorHandler);
  };

  loadRegionData = geographyResolution => {

    const {
      recordForSera,
    } = this.state;

    const loadingStateKey = geographyResolution === STATE ? 'isLoadingStates' : 'isLoadingCounties';

    this.setState({[loadingStateKey]: true});

    return Promise.all([
      this.fetchRegionTableRecords(geographyResolution),
      this.fetchRegionMapData(geographyResolution),
      recordForSera ? this.setRecordForSera(recordForSera, true) : Promise.resolve(),
    ])
    .then(() => {
      this.sortAndFilterData();
      this.setState({[loadingStateKey]: false});
    })
    .catch(this.errorHandler);
  };

  fetchRegionNames = geographyResolution => {
    if(this.state.fipsToCountyDisplayName !== null) {
      return Promise.resolve();
    }
    return fetch(`${API_ROOT_URL}/${geographyResolution}/names`)
      .then(response => response.json())
      .then(fipsRecords => {
        // TODO COVID-199: these should be aligned to either both be in state, or not
        if(geographyResolution === COUNTY) {
          this.setState({fipsToCountyDisplayName: fipsRecords});
        }
        else if(geographyResolution === STATE) {
          fipsToState = fipsRecords;
        }
      })
      .catch(this.errorHandler);
  }

  fetchGraphData = (fips, geographyResolution) => {
    const {
      graphData
    } = this.state;
    
    // if the key is present it means we've requested or already have the data
    if(fips in graphData) {
      return Promise.resolve();
    }
    
    this.setState({
      graphData: {...graphData, [fips]: null}
    });

    // eventually returning all records for a single county will be prohibitive
    return fetch(`${API_ROOT_URL}/${geographyResolution}/records/${fips}`)
      .then(response => response.json())
      .then(data => {
        const formattedData = data.map(d => ({...d, date: stringToDate(d.date)}));
        this.setState({
          graphData: {...this.state.graphData, [fips]: formattedData},
        });
      })
      .catch(this.errorHandler);
  }

  fetchRecordForSera = (fips, geographyResolution) => {
    const {
      fromDate,
      toDate,
      isLoadingSera,
    } = this.state;
    if(isLoadingSera) {
      // this prevents the browser from sending multiple simultaneous fetch requests at the same time
      return Promise.resolve();
    }
    this.setState({
      isLoadingSera: true,
    });
    return Promise.all([
      this.fetchGraphData(fips, geographyResolution),
      fetch(`${API_ROOT_URL}/${geographyResolution}/sera/${fips}?fromDate=${dateToString(fromDate)}&toDate=${dateToString(toDate)}`)
        .then(data => data.json())
        .then(recordForSera => {
          recordForSera.dates.forEach(dateRecord => dateRecord.date = stringToDate(dateRecord.date));
          this.setState({
            recordForSera,
            isLoadingSera: false,
          });
        }),
    ])
    .catch(this.errorHandler);
  };

  setRecordForSera = (newRecordForSera, forceSet = false) => {
    const {
      recordForSera: oldRecordForSera,
      geographyResolution,
    } = this.state;
    return new Promise(resolve => {
      if(!newRecordForSera) {
        this.setState({recordForSera: null}, resolve);
      }
      else if(forceSet || (oldRecordForSera && newRecordForSera.fips !== oldRecordForSera.fips)) {
        this.fetchRecordForSera(newRecordForSera.fips, geographyResolution).then(resolve);
      }
      else {
        resolve();
      }
    })
    .catch(this.errorHandler);
  };

  fetchRegionTableRecords = geographyResolution => {
    const {
      fromDate,
      toDate,
    } = this.state;

    return fetch(`${API_ROOT_URL}/${geographyResolution}/records/aggregate?fromDate=${dateToString(fromDate)}&toDate=${dateToString(toDate)}`)
      .then(data => data.json())
      .then(records => {
        // TODO COVID-199: these should be aligned to be more similar in naming and structure
        if(geographyResolution === COUNTY) {
          this.setState({countyTableRecords: records});
        }
        else if(geographyResolution === STATE) {
          this.setState({
            states: records,
            filteredRecords: records,
          });
        }
      })
      .catch(this.errorHandler);
  };

  fetchRegionPercentiles = geographyResolution => {
    return fetch(`${API_ROOT_URL}/${geographyResolution}/field/percentiles`)
      .then(data => data.json())
      .then(percentiles => {
        if(geographyResolution === COUNTY) {
          this.setState({countyPercentiles: percentiles});
        }
        else if(geographyResolution === STATE) {
          this.setState({statePercentiles: percentiles});
        }
      })
      .catch(this.errorHandler);
  };

  fetchRegionMapData = geographyResolution => {
    
    const {
      fromDate, 
      toDate,
      selectedMapField,
      fipsToCountyDisplayName,
    } = this.state;
    
    this.setState({isLoadingMapData: true});

    return fetch(`${API_ROOT_URL}/${geographyResolution}/field/dates?fromDate=${dateToString(fromDate)}&toDate=${dateToString(toDate)}&field=${encodeURIComponent(selectedMapField)}`)
      .then(data => data.json())
      .then(fieldDates => {
          const datesWithAllData = {};
          fieldDates.forEach(fieldDate => {
            const date = stringToDate(fieldDate.date);
            datesWithAllData[dateToString(date)] = fieldDate.vals.map(tuple => {
              const [fips, value] = tuple;
              const geo = geographyResolution === COUNTY ? fipsToCountyDisplayName[fips] : fipsToState[fips];
              return {
                date,
                displayName: geo.displayName,
                stateName: geo.stateName,
                fips,
                [selectedMapField]: value,
              };
            });
          });
          if(geographyResolution === COUNTY) {
            this.setState({
              dateStringsToCountyMapRecords: datesWithAllData,
              isLoadingMapData: false,
            });
          }
          else if(geographyResolution === STATE) {
            this.setState({
              dateStringsToStateMapRecords: datesWithAllData,
              isLoadingMapData: false,
            });
          }
      })
      .catch(this.errorHandler);
  };

  onSelectedMapFieldChange = selectedMapField => {
    this.setState(
      {
        selectedMapField,
        dateStringsToCountyMapRecords: {},
      },
      () => {
        this.fetchRegionMapData(this.state.geographyResolution);
    });
  };

  sortAndFilterData = () => {
    let filteredRecords = this.state.geographyResolution === STATE ? [...this.state.states] : [...this.state.countyTableRecords];
    const searchString = this.countyInput.current.value;
    if (searchString) {
      const regions = this.state.geographyResolution === STATE ? this.state.states : this.state.countyTableRecords;
      filteredRecords = regions.filter(region => region.displayName.toLowerCase().includes(searchString.toLowerCase()));
    }
    if(this.state.selectedState !== 'USA') {
      filteredRecords = filteredRecords.filter(region => region.stateName === this.state.selectedState);
    }
    
    const {
      sortBy,
      sortDescending,
    } = this.state;
    filteredRecords.sort((a, b) => {
      let toReturn = 0;
      if (a.aggregateMetrics[0][sortBy] < b.aggregateMetrics[0][sortBy]) {
        toReturn = -1;
      } else if (a.aggregateMetrics[0][sortBy] > b.aggregateMetrics[0][sortBy]) {
        toReturn = 1;
      }
      return sortDescending ? -toReturn : toReturn;
    });
    
    this.setState({ filteredRecords });
  };

  handleCountySearch = debounce(this.sortAndFilterData, 500);

  handleGeographySelectionChange = geographyResolution => {

    this.setState({ 
      geographyResolution,
      filteredRecords: [],
      // clear out all records because we're going to be viewing a different record type (county or state)
      recordForTable: null,
      recordForSera: null,
      recordForMap: null,
    }, () => {
      this.countyInput.current.value = '';
      this.loadRegionData(geographyResolution);
    });
  };

  toggleColumnChooser = category => {
    const {
      openColumnChoosers: oldOpenColumnChoosers,
    } = this.state;
    const openColumnChoosers = {...oldOpenColumnChoosers};
    if (category in openColumnChoosers) {
      delete openColumnChoosers[category];
    } else {
      openColumnChoosers[category] = true;
    }
    this.setState({ openColumnChoosers }, () => ReactTooltip.rebuild());
  };

  toggleField = fieldKey => {
    const {
      selectedFields: oldSelectedFields,
    } = this.state;
    const selectedFields = {...oldSelectedFields};
    if (fieldKey in selectedFields) {
      delete selectedFields[fieldKey];
    } else {
      selectedFields[fieldKey] = true;
    }
    this.setState({ selectedFields });
  };

  updateTooltip = tt => {
    this.setState({ tooltip: tt });
  };

  handleChartClose = () => {
    this.setState({ recordForMap: null });
  };

  handleMapRegionClick = record => {
    // protect against empty counties
    if(!record) {
      return;
    }
    const dataToSearch = this.state.geographyResolution === STATE ? this.state.states : this.state.countyTableRecords;
    const listRecord = dataToSearch.find(r => record.fips === r.fips);
    this.setState({
      // set the table's record to prompt graphs to be shown
      recordForTable: listRecord,
      // set the map's record to show the map graph
      recordForMap: listRecord,
    });
    this.setRecordForSera(listRecord);
  };

  handleFromDateChange = fromDate => {
    let {
      toDate,
      selectedDate,
      geographyResolution,
    } = this.state;
    toDate = fromDate > toDate ? fromDate : toDate;
    selectedDate = selectedDate >= fromDate && selectedDate <= toDate ? selectedDate : toDate;
    this.setState({
      fromDate,
      toDate,
      selectedDate,
    }, () => {
      this.loadRegionData(geographyResolution);
    });
  };

  handleToDateChange = toDate => {
    let {
      fromDate,
      selectedDate,
      geographyResolution,
    } = this.state;
    fromDate = toDate < fromDate ? toDate : fromDate;
    selectedDate = selectedDate >= fromDate && selectedDate <= toDate ? selectedDate : toDate;
    this.setState({
      fromDate,
      toDate,
      selectedDate,
    }, () => {
      this.loadRegionData(geographyResolution);
    });
  };

  handleSelectedDateChange = date => {
    this.setState({ selectedDate: date });
  };

  handleColumnHeaderClick = field => {
    const sortDescending = this.state.sortBy === field ? !this.state.sortDescending : true;
    this.setState({
      sortBy: field,
      sortDescending,
    }, () => {
      this.sortAndFilterData();
    });
  };

  openSera = record => {
    const {
      recordForMap,
    } = this.state;
    this.setState({
      // keep the map's graph aligned with SERA only if the map graph is open
      recordForMap: recordForMap === null ? null : record,
    });
    this.setRecordForSera(record, true); // force set SERA record
  };

  closeSera = () => {
    this.setState({
      recordForSera: null
    });
  };

  onTableSelectRow = record => {
    const {
      recordForMap,
    } = this.state;
    return new Promise(resolve => {
      this.setState({
        recordForMap: !recordForMap ? null : record,
        // counter-intuitively, clear out the record for table because logic determining which row is expanded is handled within DataTable
        recordForTable: null,
      }, () => this.setRecordForSera(record).then(resolve));
    });
  };

  render() {
    const {
      filteredRecords,
      geographyResolution,
      openColumnChoosers,
      selectedFields,
      nationRecords,

      dateStringsToStateThresholds,
      dateStringsToCountyThresholds,

      minDate,
      maxDate,
      fromDate,
      toDate,
      selectedDate,
      
      dateStringsToStateMapRecords,
      dateStringsToCountyMapRecords,
      sortBy,
      sortDescending,

      recordForSera,
      recordForMap,

      statePercentiles,
      countyPercentiles,

      isLoadingStates,
      isLoadingCounties,
      isLoadingSera,
      isLoadingMapData,

      graphData,

      selectedMapField,
    } = this.state;

    if(!selectedDate) {
      return null;
    }

    const categories = [MOBILITY, HEALTH, ECONOMY, VULNERABLE_POPULATION];
    
    const mapRecordsForSelectedGeo = geographyResolution === STATE ? dateStringsToStateMapRecords : dateStringsToCountyMapRecords;

    const mapRecords = mapRecordsForSelectedGeo ? mapRecordsForSelectedGeo[dateToString(selectedDate)] : [];

    const dateStringsToThresholds = geographyResolution === STATE ? dateStringsToStateThresholds : dateStringsToCountyThresholds;

    const percentiles = geographyResolution === STATE ? statePercentiles : countyPercentiles;

    const stateTabClassName = classnames("state", "button", {"selected": geographyResolution === STATE});
    const countyTabClassName = classnames("county", "button", {"selected": geographyResolution === COUNTY});

    const isLoading = isLoadingStates || isLoadingCounties;

    let recordForMapWithGraphData = recordForMap ? {...recordForMap, dates: graphData[recordForMap.fips]} : null;

    return (
      <div className="CovidDashboard" data-tip=''>
        {/*
        By setting the data-tip='' we trigger the creation of the ReactTooltip immediately, which works around a bug
        where on the first render the html=true setting is not applied. Since the first render is invisible with
        data-tip='' the next render -- when we're showing a real tooltip -- will have html=true applied
        */}
        <MTIHeader />
        <AnnouncementModal />
        <div className="container">
          <DashboardIntro />
          {isLoading && (
            <div className='loading-overlay'>
              <p>Loading...</p>
            </div>
          )}
          <div className="list-and-map">

            <div className="list-container">
              <div className="tab-navigator">
                <span className="tabs">
                  <div className={stateTabClassName}
                    onClick={() => this.handleGeographySelectionChange('state')}>
                    States
                  </div>
                  <div className={countyTabClassName}
                    onClick={() => this.handleGeographySelectionChange('county')}>
                    Counties
                  </div>
                </span>

                <div className="tab-navigator-content">
                  <div className="controls">
                    <div className="row">
                      <div className="date-range-selection">
                        <span>From</span>
                        <div className="datePicker">
                          <DatePicker
                            selected={fromDate}
                            minDate={minDate}
                            maxDate={maxDate}
                            dateFormat={'MMMM d, yyyy'}
                            calendarClassName="rasta-stripes"
                            className="covid-date-picker"
                            customInput={<ReadOnlyInput />}
                            onChange={this.handleFromDateChange}
                          />
                        </div>
                        <span>to</span>
                        <div className="datePicker">
                          <DatePicker
                            selected={toDate}
                            minDate={minDate}
                            maxDate={maxDate}
                            dateFormat={'MMMM d, yyyy'}
                            calendarClassName="rasta-stripes"
                            className="covid-date-picker"
                            customInput={<ReadOnlyInput />}
                            onChange={this.handleToDateChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="column-choosers">
                        <span className="select-metrics-label">Select metrics:</span>
                        {categories.map(category => {
                          const fields = FIELDS_BY_CATEGORY[category];
                          const className = classnames("column-chooser", );
                          const style = {
                            color: FIELD_CATEGORIES[category].baseColor
                          };
                          return (
                            <div key={category}
                              id={`${category}-column-chooser`}
                              className={className}
                              style={style}>
                              <button onClick={() => this.toggleColumnChooser(category)}
                                title={`Choose ${FIELD_CATEGORIES[category].name} columns`}
                                style={style}>
                                <span className='category'>
                                  <span className='name'>{formatCategoryName(FIELD_CATEGORIES[category].name)}</span>
                                  <span className='arrow'>▼</span>
                                </span>
                              </button>
                              {category in openColumnChoosers &&
                                <div className="column-checkboxes">
                                  {fields.map(fieldKey => {
                                    const field = FIELDS[fieldKey];
                                    return (
                                      <div className="column-checkbox" key={field.name}>
                                        <input type="checkbox"
                                          id={field.name}
                                          checked={fieldKey in selectedFields}
                                          onChange={() => this.toggleField(fieldKey)} />
                                        <label htmlFor={field.name}>{field.name}</label>
                                      </div>
                                    );
                                  })}
                                </div>
                              }
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className="row">
                      <input ref={this.countyInput}
                        className="countyInput"
                        placeholder={`Search for a ${geographyResolution === STATE ? 'state' : 'county'}`}
                        onChange={this.handleCountySearch} />
                    </div>
                  </div>
                  <DataTable 
                    data={filteredRecords}
                    fromDate={fromDate}
                    toDate={toDate}
                    selectedRow={this.state.recordForTable}
                    visibleColumns={selectedFields}
                    geographyResolution={geographyResolution}
                    onSelectRow={this.onTableSelectRow}
                    onColumnHeaderClick={this.handleColumnHeaderClick}
                    sortBy={sortBy}
                    sortDescending={sortDescending}
                    onSeraButtonClick={this.openSera}
                    fetchGraphData={this.fetchGraphData}
                    graphData={graphData}
                  />
                </div>
              </div>
            </div>
            <CovidMap 
              data={mapRecords}
              nationRecords={nationRecords}
              recordForSera={recordForSera}
              isLoadingSera={isLoadingSera && !isLoading}
              isLoadingMapData={isLoadingMapData && !isLoading}
              dateStringsToThresholds={dateStringsToThresholds}
              percentiles={percentiles}
              fromDate={fromDate}
              toDate={toDate}
              selectedDate={selectedDate}
              regionForChart={recordForMapWithGraphData}
              geographyResolution={geographyResolution}
              onTooltipContentChange={this.updateTooltip}
              onChartClose={this.handleChartClose}
              onRegionClick={this.handleMapRegionClick}
              onSelectedDateChange={this.handleSelectedDateChange}
              onCloseSera={this.closeSera}
              fetchGraphData={this.fetchGraphData}
              graphData={graphData}
              selectedField={selectedMapField}
              onSelectedFieldChange={this.onSelectedMapFieldChange}
            />
          </div>
          <p className="footer"> &copy; {CURRENT_YEAR} University of Maryland</p>
        </div>
        <ReactTooltip html={true} place="left" delayShow={0}>{this.state.tooltip}</ReactTooltip>
      </div>
    );
  }
}