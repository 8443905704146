import React from 'react';
import moment from 'moment';
import {
  FIELDS,
  AVAILABLE_FIELDS,
} from '../constants/Fields';
import { STATIC } from '../constants/AggregationTypes';
import CustomBarChart from './CustomBarChart';

import './NationalStats.scss';

const LONG_DATE_FORMAT_STRING = 'MMMM D, YYYY';

export default class NationalStats extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      openGraphs: {},
    };
  }

  onFieldClick = fieldKey => {
    // toggle field in openGraphs
    const openGraphs = {...this.state.openGraphs};
    if(fieldKey in openGraphs) {
      delete openGraphs[fieldKey];
    }
    else {
      openGraphs[fieldKey] = true;
    }
    this.setState({openGraphs});
  }

  getNationalStatForDates = fieldKey => {
    const {
      records,
      date,
    } = this.props;

    return FIELDS[fieldKey].displayFunction(records.find(record => {
      return record.date.getDate() === date.getDate() && record.date.getMonth() === date.getMonth() && record.date.getYear() === date.getYear();
    })[fieldKey]);
  }

  render() {
    const {
      date,
      records,
      fromDate,
      toDate,
    } = this.props;
    
    const {
      openGraphs,
    } = this.state;

    const filteredRecords = fromDate !== toDate ? records.filter(({date}) => date >= fromDate && date <= toDate) : records;

    return (
      <div className='NationalStats'>
        <div className="header">
          <div className="title">Nationwide statistics</div>
          <div className="close-button" onClick={this.props.onClose}>X</div>
        </div>
        <div className="content">
          <div className="table-item">
            <div className="label">Date</div>
            <div className="value">{
              moment(date).format(LONG_DATE_FORMAT_STRING)
            }</div>
          </div>
          {AVAILABLE_FIELDS.filter(fieldKey => FIELDS[fieldKey].aggregationType !== STATIC).map(fieldKey => {
            return (
              <div key={fieldKey} onClick={() => this.onFieldClick(fieldKey)}>
                <div className="table-item">
                  <div className="label">{FIELDS[fieldKey].name}</div>
                  <div className="value">{this.getNationalStatForDates(fieldKey)}</div>
                </div>
                {fieldKey in openGraphs &&
                  // TODO COVID-200: changing date range and viewing national bar charts leads to issues with how much data is shown: 
                  // selecting a single day in the range should show all dates in the bar charts, but doesn't after changing the range
                  <CustomBarChart
                    data={filteredRecords}
                    fieldKey={fieldKey}
                  />
                }
              </div>
            );
          })}
        </div>
      </div>
    );
  }

}