import React from 'react';
import './Credits.scss';

const Credits = () => (
  <div className="Credits">
    <div className="entry">
      <div>
        Built by <a href="https://www.cattlab.umd.edu" target="_blank" rel="noopener noreferrer">the CATT Lab</a>
      </div>
      <a href="https://www.cattlab.umd.edu" target="_blank" rel="noopener noreferrer"><img src="/cattlab-logo.svg" className="logo" alt="CATT Lab logo" /></a>
    </div>
    <div className="entry">
      <div>
        Algorithms by <a href="https://mti.umd.edu" target="_blank" rel="noopener noreferrer">Maryland Transportation Institute</a>
      </div>
      <a href="https://mti.umd.edu" target="_blank" rel="noopener noreferrer"><img className="logo" src="/mti-logo.svg" alt="UMD MTI logo" /></a>
    </div>
    <div className="entry">
      Data from _____
    </div>
  </div>
);

export default Credits;