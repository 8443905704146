import React from 'react';
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { csv } from "d3-fetch";
import moment from 'moment';
import minMax from "../utils/minMax";
import {
  STATE,
  COUNTY
} from '../constants/GeographicResolutions';

import './CasesByDateMap.scss'

const countyGeoJSONURL = "https://cdn.jsdelivr.net/npm/us-atlas@3/counties-10m.json";
const stateGeoJSONURL = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

export default class CasesByDateMap extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: null,
      minDate: null,
      maxDate: null,
      currIndex: -1,
      playing: false,
      numberOfDays: 0,
    };

    csv("/number-of-cases-by-county-and-date.csv").then(data => {
      const parsedData = [];
      const uniqueKeys = {};
      data.forEach(record => {
        const key = record.fips + ' ' + record.date;
        if(!(key in uniqueKeys)) {
          uniqueKeys[key] = true;
          parsedData.push({
            ...record,
            date: new Date(record.date),
            cases: parseInt(record.cases),
            deaths: parseInt(record.deaths),
          });
        }
      });

      const dates = parsedData.map(record => record.date);
      const [minDate, maxDate] = minMax(dates);
      const numberOfDays = moment(maxDate).diff(moment(minDate), "days"); 

      
      this.setState({
        data: parsedData,
        minDate,
        maxDate,
        numberOfDays,
        currIndex: numberOfDays,
      });
     });
  }

  componentDidMount() {
    setInterval(() => {
      const {
        currIndex,
        playing,
        numberOfDays,
      } = this.state;

      if (!playing) {
        return;
      }

      let nextIndex = Math.max(-1, Math.min(currIndex + 1, numberOfDays));
      this.setState({
        currIndex: nextIndex,
      });
      if(currIndex === numberOfDays) {
        this.setState({
          playing: false,
        });
      }
    }, 1000);
  }

  handleSliderChange = e => {
    this.setState({
      currIndex: parseInt(e.target.value),
      playing: false,
    });
  };

  handlePlayButtonClick = () => {
    const {
      playing,
      currIndex,
      numberOfDays,
    } = this.state;
    if(playing) {
      this.setState({
        playing: false,
      });
    } else {
      this.setState({
        currIndex: currIndex >= numberOfDays ? -1 : currIndex,
        playing: true,
      });
    }
  };

  render() {
    const {
      geographyResolution,
    } = this.props;
    const {
      data,
      minDate,
      currIndex,
      playing,
      numberOfDays,
    } = this.state;

    if(!data) {
      return null;
    }

    const geoUrl = geographyResolution === STATE ? stateGeoJSONURL : countyGeoJSONURL;
    const geographyLabel = geographyResolution === STATE ? 'State' : 'County';
    const geographyLabelPlural = geographyResolution === STATE ? 'States' : 'Counties';

    const currDate = moment(minDate).add(currIndex, "days").toDate();
    const dailyData = data.filter(record => {
      return record.date.getDate() === currDate.getDate() && record.date.getMonth() === currDate.getMonth() && record.date.getYear() === currDate.getYear();
    });

    let numberOfCases = 0;
    dailyData.forEach(record => numberOfCases += record.cases);

    let numberOfDeaths = 0;
    dailyData.forEach(record => numberOfDeaths += record.deaths); 

    let numberCountiesWithCases = dailyData.length;
    let numberCountiesWithDeaths = dailyData.filter(record => record.deaths > 0).length;

    const totalCounties = 3007;
    const unaffectedCounties = totalCounties - numberCountiesWithCases;

    return (
      <div className="CasesByDateMap">
        <div className="header">
          <div className="title">Reported Coronavirus cases and deaths by county</div>
          <div className="subtitle">
            {moment(currDate).add(1, 'day').format('ddd MMMM D, YYYY')}
          </div>
          <div className="player">
            <button className="playButton"
              onClick={this.handlePlayButtonClick}>
              { playing &&
                <svg width={25} height={25}>
                  <rect x={3} y={2} width={6} height={16} fill="black"/>
                  <rect x={11} y={2} width={6} height={16} fill="black" />
                </svg>
              }
              { !playing &&
                <svg width={25} height={25}>
                  <path d="M6 2 L16 10 L6 18" fill="black" />
                </svg>
              }
            </button>
            <input type="range"
              className="slider"
              min={-1}
              max={numberOfDays}
              value={currIndex}
              onChange={this.handleSliderChange}
              style={{
                width: '50%'
              }} />
          </div>
        </div>
        <div className="map">
          <ComposableMap projection="geoAlbersUsa">
            <Geographies geography={geoUrl}>
              {({ geographies }) =>
                geographies.map(geo => {
                  const curr = dailyData.find(s => s.fips === geo.id);
                  let fill = '#555';
                  if(curr) {
                    if(curr.deaths > 0) {
                      fill = '#111';
                    } else if(curr.cases > 0) {
                      fill = 'rgb(125, 44, 44)';
                    }
                  }
                  return (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      fill={fill}
                      stroke='black'
                      strokeWidth='.25px'
                    />
                  );
                })
              }
            </Geographies>
          </ComposableMap>
        </div>
        <div className="footer">
          <div>
            <div className="table-item">
              <div className="label">Date</div>
              <div className="value">{moment(currDate).add(1, "day").format('ddd MMMM D, YYYY')}</div>
            </div>
            <div className="table-item">
              <div className="label">Nationwide reported cases</div>
              <div className="value">{numberOfCases.toLocaleString()}</div>
            </div>
            <div className="table-item">
              <div className="label">Nationwide deaths</div>
              <div className="value">{numberOfDeaths.toLocaleString()}</div>
            </div>
            <div className="table-item">
              <div className="label">{geographyLabelPlural} with reported cases</div>
              <div className="value">{numberCountiesWithCases.toLocaleString()}</div>
            </div>
            <div className="table-item">
              <div className="label">{geographyLabelPlural} with deaths</div>
              <div className="value">{numberCountiesWithDeaths.toLocaleString()}</div>
            </div>
            { geographyResolution === COUNTY &&
              <div className="table-item">
                <div className="label">{geographyLabelPlural} unaffected</div>
                <div className="value">
                  {(unaffectedCounties).toLocaleString()} ({100 * Math.round(10000 * (unaffectedCounties/totalCounties))/10000}%)
                </div>
              </div>
            }
          </div>
          <div className="legend">
            <div className="legend-item">
              <div className="swatch red"></div>{geographyLabel} with at least one reported case
            </div>
            <div className="legend-item">
              <div className="swatch black"></div>{geographyLabel} with at least one death
            </div>
          </div>
        </div>
      </div>
    );
  }

}

CasesByDateMap.defaultProps = {
  geographyResolution: COUNTY,
};