import React from 'react';
import './ErrorBoundary.scss';

// based on https://reactjs.org/docs/error-boundaries.html
class ErrorBoundary extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    return {hasError: true};
  }

  render() {
    if (this.state.hasError) {
      return (
          <div className='ErrorBoundary'>
            <h1>Something went wrong...</h1>
          </div>
      );
    }
    return this.props.children; 
  }
}

export default ErrorBoundary;