import React from 'react';
import classnames from 'classnames';
import CustomBarChart from './CustomBarChart';
import ReactTooltip from 'react-tooltip';
import scrollIntoView from 'scroll-into-view';
import {
  STATE,
} from '../constants/GeographicResolutions';
import {
  FIELDS,
  AVAILABLE_FIELDS,
} from '../constants/Fields';

import './DataTable.scss';

const availableColumns = AVAILABLE_FIELDS;

export default class DataTable extends React.PureComponent {

  constructor(props) {
    super(props);
    this.listRef = React.createRef();
    this.rowsRef = React.createRef();
    this.headerRef = React.createRef();
    this.state = {
      // the fips number for the single expanded row
      expandedRowFips: null,
      listWidth: 0,
      fipsOfRowToScrollTo: null,
    };

    window.onresize = () => {
      this.setState({
        listWidth: this.listRef.current ? this.listRef.current.offsetWidth : 0,
      });
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const listWidth = this.listRef.current ? this.listRef.current.offsetWidth : 0;
    if(this.state.listWidth !== listWidth) {
      this.setState({
        listWidth: listWidth,
      });
    }
    if(this.state.fipsOfRowToScrollTo !== null && this.isExpandedRowVisible(this.state.fipsOfRowToScrollTo)) {
      const rowEl = document.getElementById(this.state.fipsOfRowToScrollTo);
      if(rowEl) {
        setTimeout(() => {
          scrollIntoView(rowEl, {
              time: 500,
              align: {
              top: 0,
              left: 0,
            },
          });
        });
        this.setState({fipsOfRowToScrollTo: null});
      }
      else {
        console.error("this shouldn't happen");
      }
    }
    if(this.props.selectedRow && this.props.selectedRow !== prevProps.selectedRow) {
      this.selectRow(this.props.selectedRow);
    }
  }

  selectRow = (record, toggleExpanded = false) => {
    if(toggleExpanded) {
      this.toggleRowExpanded(record);
    }
    else {
      this.expandRow(record);
    }
    this.props.onSelectRow(record).then(() => {
      this.setState({fipsOfRowToScrollTo: record.fips});
    });
  }

  componentDidMount() {
    const headerEl = this.headerRef.current;
    this.rowsRef.current.onscroll = ({target}) => headerEl.style.left = `-${target.scrollLeft}px`;
  }

  componentWillUnmount() {
    this.rowsRef.current.onscroll = undefined;
  }

  toggleRowExpanded = record => {
    const {
      expandedRowFips,
    } = this.state;
    let newExpandedRowFips = null;
    if (expandedRowFips !== record.fips) {
      newExpandedRowFips = record.fips;
    }
    this.setState({
      expandedRowFips: newExpandedRowFips,
    });
  };

  expandRow = record => {
    this.setState({
      expandedRowFips: record.fips,
    })
  };

  handleColumnHeaderClick = fieldKey => {
    this.props.onColumnHeaderClick(fieldKey);
  };

  handleSeraButtonClick = (e, record) => {
    e.stopPropagation();
    this.props.onSeraButtonClick(record);
  };

  isExpandedRowVisible = fips => {
    const {
      graphData,
    } = this.props;
    const {
      expandedRowFips,
    } = this.state;
    return fips === expandedRowFips && fips in graphData && graphData[fips] !== null;
  };

  getExpandedRowContent = (record, columns) => {
    // don't show anything if not expanded
    if(record.fips !== this.state.expandedRowFips) {
      return null;
    }
    
    const {
      fromDate,
      toDate,
      graphData,
      fetchGraphData,
      geographyResolution,
    } = this.props;
    
    if(!this.isExpandedRowVisible(record.fips)) {
      // setTimeout prevents setState from being called within render. Hacky solution :(
      setTimeout(() => fetchGraphData(record.fips, geographyResolution));
      return 'Loading...';
    }

    const {
      listWidth,
    } = this.state;
    
    const chartWidth = listWidth - 40;

    return (
      <div className="chart-sub-row" key={`expanded-chart-${record.fips}`}>
        {columns.map(field => {
          let dataToFilter = graphData[record.fips];
          let filteredData = dataToFilter;
          if(fromDate.valueOf() !== toDate.valueOf()) {
            filteredData = dataToFilter.filter(dateRecord => dateRecord.date >= fromDate && dateRecord.date <= toDate);
          }
          return (
            <div className="chart" key={`${record.fips}-${field}-chart`}>
              <div className="chart-title">{FIELDS[field].name} over time</div>
              <CustomBarChart
                data={filteredData}
                fieldKey={field}
                width={chartWidth}
              />
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const {
      data,
      visibleColumns,
      geographyResolution,
      sortBy,
      sortDescending,
    } = this.props;
    const {
      expandedRowFips,
    } = this.state;

    const columns = availableColumns.filter(fieldKey => fieldKey in visibleColumns);

    return (
      <div className="DataTable" ref={this.listRef}>
        <ReactTooltip id="DataTable-tooltip" delayShow={100} className="DataTable-tooltip" />
        <div className="header-row">
          <div className="data-sub-row" ref={this.headerRef}>
            <div className="name header-cell"
              onClick={() => this.handleColumnHeaderClick("displayName")}>
              <span>{geographyResolution === STATE ? 'State' : 'County'}</span>
              {sortBy === 'displayName' &&
                <span className="sort-indicator">
                  {sortDescending ? '▼' : '▲'}
                </span>
              }
            </div>
            {columns.map(fieldKey => {
              return (
                <div 
                  key={fieldKey}
                  className={FIELDS[fieldKey].className + ' header-cell'}
                  onClick={() => this.handleColumnHeaderClick(fieldKey)}
                  data-tip={FIELDS[fieldKey].description}
                  data-for="DataTable-tooltip"
                >
                    <span>{FIELDS[fieldKey].name}</span>
                    {sortBy === fieldKey &&
                      <span className="sort-indicator">
                        {sortDescending ? '▼' : '▲'}
                      </span>
                    }
                </div>
              );
            })}
            <div className="header-cell sera">
              <span>&nbsp;</span>
            </div>
          </div>
        </div>
        <div className="rows" ref={this.rowsRef}>
          {data.map(record => {
            const className = classnames(
              "data-row",
              {
                "selected": record === this.props.selectedRow,
                "expanded": record.fips === expandedRowFips,
              }
            );
            return (
              <div 
                className={className}
                key={record.fips}
                id={record.fips}
                onClick={() => this.selectRow(record, true)}
              >
                <div className="data-sub-row">
                  <div className="name">{record.displayName}</div>
                  {columns.map(fieldKey => {
                    return (
                      <div key={fieldKey} className={FIELDS[fieldKey].className}>
                        {FIELDS[fieldKey].displayFunction(record.aggregateMetrics[0][fieldKey])}
                      </div>
                    );
                  })}
                  <div className="sera">
                    <button className="sera-button"
                      title="Open Society and Economy Reopening Assessment"
                      onClick={(e) => this.handleSeraButtonClick(e, record)}>SERA</button>
                  </div>
                </div>
                {this.getExpandedRowContent(record, columns)}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}