const moment = require('moment');

const DATE_FORMAT = 'YYYY-MM-DD';

const dateToString = d => moment(d).format(DATE_FORMAT);

const stringToDate = dateString => {
  const parts = dateString.split('-');
  return new Date(parts[0], parts[1] - 1, parts[2]);
};

module.exports = {
  DATE_FORMAT,
  dateToString,
  stringToDate,
};