import React from 'react';
// import Cookies from 'js-cookie';
import './AnnouncementModal.scss';

// COVID-203: we're currently not keeping any cookies
/*
const COOKIENAME = 'announcement';
// const COOKIEVALUE = Math.random();
const COOKIEVALUE = 'v2'; // whenever this const changes, users will see the announcement the next time they open the app
*/

const AnnouncementModal = () => {

	const [isVisible, setIsVisible] = React.useState(true);

	const onClick = React.useCallback(() => {
		// Cookies.set(COOKIENAME, COOKIEVALUE);
		setIsVisible(false);
	}, [setIsVisible]);

	// COVID-203: we're currently always starting the modal as visible
	/*React.useEffect(() => {
		// if user's cookie is set to COOKIEVALUE, then we know they've seen the announcement
		if(Cookies.get(COOKIENAME) === COOKIEVALUE) {
			setIsVisible(false);
		}
	}, [setIsVisible]);*/

	if(!isVisible) {
		// returning null means no announcement
		return null;
	}

	return (
		<div 
			className='AnnouncementModal'
		>	
			<article>
        		<h1>Important information about further updates</h1>
				<div>
					<p>
					The COVID Impact Platform was <b>last updated April 20, 2021</b>, and will not receive further updates.<br />The platform will continue to be available for historical analysis.
					</p>
				</div>
				<section>
					<button onClick={onClick}>Got it</button>
				</section>
			</article>
		</div>
	);
};

export default AnnouncementModal;