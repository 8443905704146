import React from 'react';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import {
  FIELD_CATEGORIES,
} from '../constants/FieldCategories';
import {
  FIELDS,
  FIELDS_BY_CATEGORY,
} from '../constants/Fields';
import {dateToString} from '../utils/dateUtils';
import {
  WARN,
  FAIL,
  PASS,
  NOT_APPLICABLE,
} from '../constants/Feedback';
import CustomBarChart from './CustomBarChart';
import classnames from 'classnames';

import './SERA.scss';

const graphCutoffDate = new Date('3/1/2020');

// From https://stackoverflow.com/a/13627586
function getOrdinalSuffix(i) {
  if(i === null) {
    return 'N/A';
  }
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
}

export default class SERA extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      openGraphs: {},
    };
  }

  onFieldClick = fieldKey => {
    // toggle field in openGraphs
    const openGraphs = {...this.state.openGraphs};
    if(fieldKey in openGraphs) {
      delete openGraphs[fieldKey];
    }
    else {
      openGraphs[fieldKey] = true;
    }
    this.setState({openGraphs});
  }

  render() {
    const {
      date,
      record,
      dateStringsToThresholds,
      graphData,
      isLoadingSera,
    } = this.props;

    if(isLoadingSera && record === null) {
      return (
        <div className="SERA">
          <div className='content'>
            <section className='body'>
              <div className='loader'>
                <p>Loading...</p>
              </div>
            </section>
          </div>
        </div>
      );
    }

    const {
      openGraphs,
    } = this.state;

    let thresholds = dateStringsToThresholds[dateToString(date)];
    const dailyRecord = record.dates.find(dateRecord => {
      return dateRecord.date.getDate() === date.getDate() && dateRecord.date.getMonth() === date.getMonth() && dateRecord.date.getYear() === date.getYear();
    });

    if(!dailyRecord) {
      console.log(record);
      throw Error(`No dailyRecord for date '${date}'`);
    }

    return (
      <div className="SERA">
        <ReactTooltip id='sera-tooltip' place="left" html={true} />
        {
          isLoadingSera && <div className='loader'>
            <p>Loading...</p>
          </div>
        }
        <div className="content">
          <header>
            <div className="title">{record.displayName} - Society and Economy Reopening Assessment</div>
            <div className="close-button" onClick={this.props.onClose}>X</div>
          </header>
          <section className="body">
            <div className="info">
              <div className="date">{moment(date).format('MMMM D, YYYY')}</div>
              <div className="learn-more">
                <a href="methods/index.html">Learn more about SERA results</a>
              </div>
              <div className="feedback-key">
                <div className="pass">Passing</div>
                <div className="warn">Narrowly Passing</div>
                <div className="fail">Failing</div>
              </div>
            </div>
            { Object.keys(FIELDS_BY_CATEGORY).map(categoryKey => {
              const category = FIELD_CATEGORIES[categoryKey];
              const fields = FIELDS_BY_CATEGORY[categoryKey].filter(fieldKey => FIELDS[fieldKey].isSeraComponent);
              return (
                <div className="category" key={categoryKey}>
                  <div className={`category-title ${category.className}`}>{category.name}</div>
                  { fields.map(fieldKey => {
                    const field = FIELDS[fieldKey];

                    const fieldValue = dailyRecord[fieldKey];

                    let absoluteThresholdValue = null;
                    if (thresholds && fieldKey in thresholds) {
                      absoluteThresholdValue = thresholds[fieldKey].absolute;
                    }

                    let feedback = null;
                    if(field.feedbackFunction) {
                      feedback = field.feedbackFunction(fieldValue);
                    }

                    const tooltipMessages = [];

                    if(feedback === WARN) {
                      tooltipMessages.push('Narrowly meeting this threshold');
                    } else if(feedback === FAIL) {
                      tooltipMessages.push('Failing to meet this threshold');
                    } else if (feedback === PASS) {
                      tooltipMessages.push('Successfully meeting this threshold');
                    } else if (feedback === NOT_APPLICABLE) {
                      tooltipMessages.push(`We do not have data for this field for ${record.displayName} for this day`);
                    }

                    let rankValue;
                    let rankTopOrBottom;
                    const rankField = fieldKey + ' rank';
                    if (rankField in dailyRecord) {
                      rankValue = dailyRecord[rankField];
                      if (rankValue !== null) {
                        if (rankValue <= 20) {
                          tooltipMessages.push('Top performer in this category relative to others');
                          rankTopOrBottom = 'top';
                        } else if (rankValue >= 80) {
                          tooltipMessages.push('Poor performer in this category relative to others');
                          rankTopOrBottom = 'bottom';
                        }
                      }
                      rankValue = getOrdinalSuffix(rankValue);
                    }
                    // creates a bullet list when more than one tooltip message is present
                    const tooltipMessage = tooltipMessages.length > 1 
                      ? tooltipMessages.map(m => '&#9679; ' + m).join('<br>')
                      : tooltipMessages.join('');

                    const graphDataForRegion = graphData[record.fips];
                    
                    return (
                      <div 
                        key={fieldKey}
                        className='field-wrapper'
                      >
                      <div 
                        className="field" 
                        data-for="sera-tooltip"
                        data-tip={tooltipMessage}
                        onClick={() => this.onFieldClick(fieldKey)}
                      >
                        <div className="entry field-feedback">
                          <div className={feedback} /> 
                        </div>
                        <div className="field-info">
                          <div className="field-title">{field.name}</div>
                          <div className="field-entries">
                            <div className="entry field-value">
                              <div className={classnames(["value", feedback])}>{field.displayFunction(fieldValue)}</div>
                            </div>
                            {absoluteThresholdValue !== null &&
                              <div className="entry field-threshold">
                                <div className="label">Threshold</div>
                                <div className={classnames(["value", feedback])}>{field.displayFunction(absoluteThresholdValue)}</div>
                              </div>
                            }
                            {absoluteThresholdValue === null && <div className="entry field-threshold"></div> }
                            <div className={classnames(["entry field-percentile", rankTopOrBottom])}>
                              {rankTopOrBottom === 'top' &&
                                <div className="rank-top"/>
                              }
                              {rankTopOrBottom === 'bottom' &&
                                <div className="rank-bottom" />
                              }
                              <div className="label">Percentile</div>
                              <div className="value">{rankValue}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {fieldKey in openGraphs && graphDataForRegion && <div className="chart" key={`${fieldKey}-sera-chart`}>
                        <CustomBarChart 
                          data={graphDataForRegion.filter(({date}) => date > graphCutoffDate)}
                          fieldKey={fieldKey}
                        />
                      </div>}
                    </ div>
                    );
                  }) }
                </div>
              );
            })}
          </section>
        </div>
      </div>
    )
  }
}