const MOBILITY = "mobility";
const HEALTH = "health";
const ECONOMY = "economy";
const VULNERABLE_POPULATION = "vulnerablePopulation";

const FIELD_CATEGORIES = {};
FIELD_CATEGORIES[HEALTH] = {
  name: "COVID and Health",
  className: "health",
  baseColor: "#C94CA8",
  gradientTopColor: "#ff99e0",
};
FIELD_CATEGORIES[MOBILITY] = {
  name: "Mobility and Social Distancing",
  className: "mobility",
  baseColor: "#83A7ec",
  gradientTopColor: "#B9DAFC",
};
FIELD_CATEGORIES[ECONOMY] = {
  name: "Economic Impact",
  className: "economy",
  baseColor: "#3DC986",
  gradientTopColor: "#89F7C0",
};
FIELD_CATEGORIES[VULNERABLE_POPULATION] = {
  name: "Vulnerable Population",
  className: "vulnerable-population",
  baseColor: "#df9530",
  gradientTopColor: "#ffb973",
};


module.exports = {
  MOBILITY,
  HEALTH,
  ECONOMY,
  VULNERABLE_POPULATION,
  FIELD_CATEGORIES,
};