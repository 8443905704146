import React from 'react';
import ReactTooltip from 'react-tooltip';
import { BarChart, Bar, XAxis, YAxis } from 'recharts';
import { json } from "d3-fetch";
import { geoPath, geoTransverseMercator } from "d3-geo";
import { scaleThreshold } from "d3-scale";
import * as topojson from "topojson-client";
import moment from 'moment';
import {
  STATE,
  COUNTY,
} from '../constants/GeographicResolutions';
import {
  FIELDS,
  AVAILABLE_FIELDS,
  POPULATION,
  COVID_TREND,
  ILI_TREND,
} from '../constants/Fields';
import hexToRgb from "../utils/hexToRgb";
import NationalStats from './NationalStats';
import SERA from './SERA';
import debounce from "lodash/debounce";
import CovidComposableMap from './CovidComposableMap';

import './CovidMap.scss';

const LONG_DATE_FORMAT_STRING = 'MMMM D, YYYY';

const getColorScaleRange = (fieldObject, length) => {
  const baseRGB = hexToRgb(fieldObject.color);
  const colorScaleRange = [];
  for(let i = 0; i < length; i++) {
    // this value won't be used in the color scale
    colorScaleRange.push(`rgba(${baseRGB.r}, ${baseRGB.g}, ${baseRGB.b}, ${i / length})`);
  }
  // final value will be the legend color
  colorScaleRange.push(fieldObject.topLegendColor);
  return colorScaleRange;
}

const availableColumns = AVAILABLE_FIELDS;

const STATES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District of Columbia',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export default class CovidMap extends React.PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      minDate: null,
      maxDate: null,
      playing: false,
      numberOfDays: 0,
      selectedState: "USA",
      stateGeoJSON: null,
      countyGeoJSON: null,
      showNationalStats: false,
      countyData: null,
      countyColorScales: null,
      stateColorScales: null,
      showChart: false,
      regionForChart: null,
    };

    json("states-10m.json").then(usa => {
      const usaTopoJSON = topojson.feature(usa, usa.objects.states);
      this.setState({
        stateGeoJSON: usa,
        usaTopoJSON: usaTopoJSON,
      })
    });
    json("counties-10m.json").then(r => {
      this.setState({ countyGeoJSON: r })
    });
  }

  async componentDidMount() {
    setInterval(() => {
      const {
        playing,
      } = this.state;

      if (!playing) {
        return;
      }

      let selectedMoment = moment(this.props.selectedDate);
      selectedMoment.add(1, "day");
      const endMoment = moment(this.props.toDate);
      if (selectedMoment.isAfter(endMoment)) {
        this.setState({
          playing: false,
        })
      } else {
        this.props.onSelectedDateChange(selectedMoment.toDate());
      }
    }, 1000);
  }

  handleSliderChange = e => {
    this.setState({
      playing: false,
    });

    let selectedMoment = moment(this.props.fromDate).add(e.target.value, "day");
    this.props.onSelectedDateChange(selectedMoment.toDate());
  };

  handlePlayButtonClick = () => {
    const {
      playing,
    } = this.state;
    if(playing) {
      this.setState({
        playing: false,
      });
    } else {
      let selectedMoment = moment(this.props.selectedDate);
      selectedMoment.add(1, "day");
      const endMoment = moment(this.props.toDate);
      if (selectedMoment.isAfter(endMoment)) {
        selectedMoment = moment(this.props.fromDate);
      }
      this.props.onSelectedDateChange(selectedMoment.toDate());

      setTimeout(() => {
        this.setState({
          playing: true,
        });
      }, 500);
    }
  };

  handleSelectedFieldChange = e => {
    const select = e.target;
    const selectedField = select.options[select.selectedIndex].value;
    this.props.onSelectedFieldChange(selectedField);
  };

  handleSelectedStateChanged = e => {
    const select = e.target;
    const selectedState = select.options[select.selectedIndex].value;
    this.setState({ selectedState });
  };

  setTooltipContent = debounce(tt => {
    ReactTooltip.rebuild();
    this.props.onTooltipContentChange(tt);
  }, 5);

  createTooltipContent = record => {
    const field = FIELDS[this.props.selectedField];
    let tt = `<div>`;
    tt += `<b>${record.displayName}</b>`;
    tt += `<div>Date: ${moment(record.date).format(LONG_DATE_FORMAT_STRING)}</div>`
    /*
    availableColumns.forEach(fieldKey => {
      tt += `<div>${FIELDS[fieldKey].name}: ${record[fieldKey].toLocaleString()}</div>`;
    });
    */
    tt += `<div>${field.name}: ${field.displayFunction(record[this.props.selectedField])}</div>`;
    tt += '</div>'
    return tt;
  };

  toggleNationStats = () => {
    this.setState({
      showNationalStats: !this.state.showNationalStats,
    });
  };

  handleSeraClose = () => {
    this.setState({
      showNationalStats: false,
    }, () => {
      this.props.onCloseSera()
    });
  };

  componentDidUpdate = prevProps => {
    const {
      recordForSera,
      geographyResolution,
      percentiles,
      regionForChart,
    } = this.props;
    
    // keep any changed regionForChart in state
    // we do this to improve feedback when the map is clicked
    if(regionForChart !== prevProps.regionForChart) {
      const showChart = regionForChart !== null;
      this.setState({regionForChart, showChart});
    }

    if(recordForSera && prevProps.recordForSera !== recordForSera) {
      this.props.fetchGraphData(this.props.recordForSera.fips, geographyResolution);
    }

    // set color scales based on percentiles
    // we only do this if color scares aren't defined because they never change over the app's lifetime
    if(percentiles) {
      if((!this.state.countyColorScales && geographyResolution === COUNTY) || (!this.state.stateColorScales && geographyResolution === STATE)) {
        const colorScales = {};
        AVAILABLE_FIELDS.forEach(field => {
          const values = percentiles[field];
          colorScales[field] = scaleThreshold().domain([...values]).range(getColorScaleRange(FIELDS[field], values.length));
        });

        if(geographyResolution === COUNTY) {
          this.setState({countyColorScales: colorScales});
        }
        
        else if(geographyResolution === STATE) {
          this.setState({stateColorScales: colorScales}); 
        }
      }
    }
  };

  handleChartClose = () => {
    this.setState(
      {
        showChart: false,
        regionForChart: null,
      },
      () => setTimeout(this.props.onChartClose),
    );
  };

  handleRegionClick = region => {

    const {
      regionForChart,
    } = this.state;

    this.setState(
      {
        showChart: true,
        // only update regionForChart if a new region was clicked
        regionForChart: regionForChart && regionForChart.fips === region.fips ? regionForChart : null,
      },
      () => setTimeout(() => this.props.onRegionClick(region), 100),
    );
  };

  render() {
    const {
      data,
      geographyResolution,
      nationRecords,
      fromDate,
      toDate,
      selectedDate,
      recordForSera,
      isLoadingSera,
      isLoadingMapData,
      dateStringsToThresholds,
      graphData,
      selectedField,
    } = this.props;

    const {
      playing,
      selectedState,
      usaTopoJSON,
      showNationalStats,
      stateGeoJSON,
      countyGeoJSON,
      countyColorScales,
      stateColorScales,
      showChart,
      regionForChart,
    } = this.state;

    if(!data || !nationRecords || !stateGeoJSON || !countyGeoJSON || !fromDate || !toDate) {
      return null;
    }

    const colorScales = geographyResolution === STATE ? stateColorScales : countyColorScales;

    const includePlaybackControls = fromDate.valueOf() !== toDate.valueOf() && FIELDS[selectedField].isFixed !== true;
    
    const showSera = recordForSera || isLoadingSera;

    const mapStyle = {
      right: showNationalStats || showSera ? '325px' : '10px',
      bottom: includePlaybackControls ? '110px' : '65px',
    };

    const sidebarStyles = {
      bottom: showSera || showNationalStats ? '1px' : 'unset',
    };

    const playerContainerStyles = {
      left: 0,
      right: showSera || showNationalStats ? '325px' : '10px',
    };

    let colorScale = colorScales ? colorScales[selectedField] : null;
    if (colorScale && selectedField === POPULATION) {
      if (geographyResolution === STATE) {
        colorScale.domain([500000, 6000000, 12000000, 18000000, 24000000]);
      } else {
        colorScale.domain([80, 200000, 400000, 600000, 800000]);
      }
    }
    else if (colorScale && (selectedField === COVID_TREND || selectedField === ILI_TREND)) {
      colorScale.domain([0, 1, 3, 7, 14]);
    }

    const geography = geographyResolution === STATE ? stateGeoJSON : countyGeoJSON;

    /*
    let x = {};
    if (usaGeoJSON) {
      x = topojson.mesh(usaGeoJSON, usaGeoJSON.objects.states, (a, b) => a !== b);
    }
    */
    
    let projection = 'geoAlbersUsa';
    if(selectedState !== 'USA') {
      projection = geoTransverseMercator();
      const element = document.querySelector(".CovidMap .map");
      if (element && usaTopoJSON) {
        const selectedTopo = usaTopoJSON.features.find(feature => feature.properties.name === selectedState);
        const centroid = geoPath().centroid(selectedTopo);
        projection = geoTransverseMercator()
          .rotate([-centroid[0], -centroid[1]])
          .fitExtent([[20, 20], [800 - 20, 600 - 20]], selectedTopo)
      }
    }

    const sliderProps = {
      min: 0,
      max: moment(toDate).diff(moment(fromDate), "days"),
      value: moment(selectedDate).diff(moment(fromDate), "days")
    };
    
    let chartData;
    if(regionForChart) {
      chartData = regionForChart.dates;
    }

    return (
      <div className="CovidMap">
        
        <div className="controls">
          <div className="zoom-controls">
            <span style={{ marginRight: '5px' }}>Zoom to</span>
            <select onChange={this.handleSelectedStateChanged}
              value={selectedState}>
              <option value='USA'>All states</option>
              {STATES.map(state => 
                <option key={state} value={state}>{state}</option>
              )}
            </select>
          </div>

          <div className="measures-selection" style={{flexGrow: 1}}>
            <span style={{ marginRight: '5px' }}>Show</span>
            <select onChange={this.handleSelectedFieldChange} value={selectedField}>
              {availableColumns.map(field =>
                <option key={field} 
                  value={field}>{FIELDS[field].name}</option>
              )}
            </select>
          </div>

          <div className="selected-date-label">
            <div>Showing data for {moment(selectedDate).format(LONG_DATE_FORMAT_STRING)}</div>
          </div>

        </div>

        <div className="sidebar"
          style={sidebarStyles}>
          { (showSera) && (
              <SERA
                geographyResolution={geographyResolution}
                record={recordForSera}
                isLoadingSera={isLoadingSera}
                date={selectedDate}
                dateStringsToThresholds={dateStringsToThresholds}
                onClose={this.handleSeraClose}
                graphData={graphData}
              />
            )
          }
          {!showSera && !showNationalStats &&
            <div className="national-stats-button"
              onClick={this.toggleNationStats}>Show National Statistics</div>
          }
          {!showSera && showNationalStats &&
            <NationalStats
              date={selectedDate}
              records={nationRecords}
              onClose={this.toggleNationStats}
              fromDate={fromDate}
              toDate={toDate}
            />
          }
        </div>

        {showChart && (
          <div className="chart-overlay">
            <div className="chart">
              <div className="header">
                <div className="region-name">{chartData ? regionForChart.displayName : 'Loading...'}</div>
                <div className="close-button" onClick={this.handleChartClose}>X</div>
              </div>
              <div className="chart-title">{FIELDS[selectedField].name} over time</div>
              {chartData && (
                <BarChart width={275} height={100} data={chartData} barCategoryGap={0}>
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Bar dataKey={selectedField} fill={FIELDS[selectedField].color} isAnimationActive={false} />
                </BarChart>
              )}
            </div>
          </div>
        )}

        <div className={`map ${isLoadingMapData ? 'isLoading' : ''}`} data-html={true} style={mapStyle}>
          {isLoadingMapData && <div className='loading-wrapper'><p>Loading...</p></div>}
          <CovidComposableMap
            projection={projection}
            geography={geography}
            setTooltipContent={this.setTooltipContent}
            createTooltipContent={this.createTooltipContent}
            data={data}
            onRegionClick={this.handleRegionClick}
            colorScale={colorScale}
            selectedField={selectedField}
            selectedState={selectedState}
            geographyResolution={geographyResolution}
          />
        </div>
        
        <div className="player-container"
          style={playerContainerStyles}>
          <div className="legend-container">
            <div className="legend-label">{FIELDS[selectedField].name}</div>
            {colorScale && <div className="legend">
              {
                colorScale.range().map((color, i) => {
                  // skip the first value in the range
                  // scaleThreshold's first item is for values beneath the minimum; we should never have these
                  if(i === 0) {
                    return null;
                  }
                  const displayFunction = FIELDS[selectedField].displayFunction;
                  let textColor = '';
                  if(i <= 2) {
                    textColor = 'white';
                  } else {
                    textColor = 'black';
                  }
                  const legendItemStyle = {
                    color: textColor,
                    background: color,
                  }

                  let content = displayFunction(colorScale.invertExtent(color)[0]);
                  if (i === colorScale.range().length - 1) {
                    content += "+";
                  }

                  return (
                    <div key={i} className="legend-item" style={legendItemStyle}>{content}</div>
                  );
                })
              }
            </div>}
          </div>
          
          {includePlaybackControls &&
            <React.Fragment>
              <div className="player">
                <button className="playButton"
                  onClick={this.handlePlayButtonClick}>
                  {playing &&
                    <svg width={25} height={25}>
                      <rect x={3} y={2} width={6} height={16} fill="black" />
                      <rect x={11} y={2} width={6} height={16} fill="black" />
                    </svg>
                  }
                  {!playing &&
                    <svg width={25} height={25}>
                      <path d="M6 2 L16 10 L6 18" fill="black" />
                    </svg>
                  }
                </button>
                <input type="range"
                  className="slider"
                  min={sliderProps.min}
                  max={sliderProps.max}
                  value={sliderProps.value}
                  onChange={this.handleSliderChange} />
              </div>
              <div className="date-labels">
                <div>{moment(fromDate).format(LONG_DATE_FORMAT_STRING)}</div>
                <div>{moment(toDate).format(LONG_DATE_FORMAT_STRING)}</div>
              </div>
            </React.Fragment>
          }
        </div>
      </div>
    );
  }

}

CovidMap.defaultProps = {
  geographyResolution: STATE,
  onTooltipContentChange: () => {},
  data: [],
};