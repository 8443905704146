import React from 'react';
import ReactDOM from 'react-dom';
import ErrorBoundary from './components/ErrorBoundary';
import CovidArticle from './CovidArticle';
import CovidDashboard from './CovidDashboard';

import './index.scss';

const rootElement = document.getElementById('root');

// need to check, because in IE11 root will be null
if(rootElement != null) {
	if(window.MODE === 'dashboard') {
	 	ReactDOM.render(<ErrorBoundary><CovidDashboard /></ErrorBoundary>, rootElement);
	} else {
	 	ReactDOM.render(<CovidArticle />, rootElement);
	}
}
